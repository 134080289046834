import classNames from "classnames";
import {DrupalMedia, DrupalNode, DrupalTaxonomyTerm} from "next-drupal";
import {useContext, useEffect, useRef, useState} from "react";
import {Dictionary, DrupalProductNode} from "@/types/hygiena-types";
import {useRouter} from "next/router";
import {MediaImage} from "../templates/media/media--image";
import {THUMBNAIL_IMAGE_STYLE} from "@/types/image-styles";
import {Button} from "../atoms/button";
import parse from "html-react-parser";
import dynamic from "next/dynamic";
import {DictionaryContext} from "@/context/dictionary-context";
import {Select, SelectContent, SelectItem, SelectTrigger} from "@/components/shadcn/ui/select";
import {SelectValue} from "@radix-ui/react-select";
import {Pagination} from "@/components/organisms/widget--pagination";

const NodeProductTeaser = dynamic(() => import("../templates/nodes/node--product--teaser").then((mod) => mod.NodeProductTeaser));
const NodeSubProduct = dynamic(() => import("../templates/nodes/node--product--subproduct").then((mod) => mod.NodeSubProduct));
const NodeSubProductContainer = dynamic(() => import("../templates/nodes/node--product--subproduct").then((mod) => mod.NodeSubProductContainer));

/**
 * Displays a product list.
 *
 * @param nodes
 * @constructor
 */
export function ProductList({ nodes }: {
  nodes?: DrupalProductNode[],
}) {

  function getDisplayTitle(item: DrupalProductNode) {
    let title = item?.field_product_display_title?.processed?.length
      ? item?.field_product_display_title?.processed
      : item.title;
    return (
      <>
        {item?.field_product_brand?.name ? (
          <>{parse(`${item?.field_product_brand?.name}${item?.field_product_brand?.field_brand_suffix ?? ""} ${title}`)}</>
        ): parse(title)}
      </>
    )
  }

  return (
    <div className="flex flex-col gap-1 py-2">
      {nodes?.map(item => (
        <div key={`product-list--${item?.id}`} className={classNames(
          "bg-white rounded-md drop-shadow-sm py-2 px-8"
        )}>
          <div className="flex items-center gap-6">
            {item?.field_product_image?.[0] && (
              <MediaImage
                sizes={"100px"}
                className="h-[60px] w-[100px] flex"
                loading="lazy"
                height={150}
                width={150}
                imageStyle={THUMBNAIL_IMAGE_STYLE}
                style={{objectFit: "contain", objectPosition: "center center"}}
                media={item.field_product_image[0]}/>
            )}
            <div className="text-2xl flex-grow w-full [&>p]:inline">
              <>{getDisplayTitle(item)}</>
            </div>
            <div>
              <Button icon={"search"} invert={true} hover={{color: "primary"}} iconClass={"!px-0 !mr-0 !ml-0"} href={item?.path?.alias} height={20} width={20}/>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

/**
 * Displays product teasers in a grid of 2s or 3s depending on product count.
 *
 * @todo also contains filters for the products which may be advisable to rip out
 *   into a separate component.
 *
 * @param nodes
 *   An array of Product nodes.
 * @param fixed
 *   Set to 2,3 or 4 fix to that many columns.
 * @param filters
 *   Set to true to display filters.
 * @param className
 *   Optional className to be applied.
 * @param filterClassName
 *   Optional className for filters.
 * @param noFiltersClassName
 *   Optional classNames for when no filters present.
 * @param nodeorder
 *   A node order array, for ordering products.
 * @param paginate
 *   Will display a limited number when no filters are set.
 * @constructor
 */
export function DynamicProductGrid({
  nodes,
  fixed,
  filters = true,
  className,
  filterClassName,
  noFiltersClassName,
  nodeorder,
  paginate = false,
}: {
  nodes?: DrupalProductNode[]
  fixed?: 2|3|4
  filters?: boolean,
  className?: string,
  filterClassName?: string,
  noFiltersClassName?: string,
  nodeorder?: {[key: string]: string}[],
  paginate?: number | false,
}) {
  // @todo a very specific set of filters for now, we may wish to move this filter functionality into
  //   a separate component.
  let subtype;
  let products: DrupalProductNode[];
  let testTargets: DrupalTaxonomyTerm[] = [];
  let brands: DrupalTaxonomyTerm[] = [];
  let hasFilters;
  let filterable = false;
  let certificates: DrupalMedia[] = [];
  let technologies: DrupalTaxonomyTerm[] = [];
  let noCertificateFilter = false;
  let availableFilterIds: string[] = [];
  const router = useRouter();
  const [certificateFilter, setCertificateFilter] = useState<string|string[]|undefined>();
  const [targetFilter, setTargetFilter] = useState<string|string[]|undefined>();
  const [technologyFilter, setTechnologyFilter] = useState<string|string[]|undefined>();
  const [brandFilter, setBrandFilter] = useState<string|string[]|undefined>();
  const [kitFilter, setKitFilter] = useState<"single"|"multi"|undefined>();
  const t = useContext<Dictionary>(DictionaryContext);
  const gridRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const pageLimit = paginate ? paginate : 0;
  let productCount = 0;

  const adjustFieldHeights = () => {
    if (!gridRef.current) return;

    const fieldsToMatch = [
      '.product-teaser--title',
      '.product-teaser--attributes',
      '.product-teaser--body',
      '.product-teaser--product-number'
    ];

    // Reset and get initial heights
    fieldsToMatch.forEach(selector => {
      gridRef.current?.querySelectorAll<HTMLElement>(selector).forEach(el => {
        el.style.height = '';
      });
    });

    const teasers = Array.from(gridRef.current.querySelectorAll('.product--teaser'));
    const rows = new Map<number, HTMLElement[]>();

    teasers.forEach((teaser: HTMLDivElement) => {
      const offsetTop = teaser.offsetTop;
      if (!rows.has(offsetTop)) {
        rows.set(offsetTop, []);
      }
      rows.get(offsetTop)?.push(teaser);
    });

    rows.forEach((rowTeasers, offsetTop) => {
      fieldsToMatch.forEach(fieldClass => {
        const elements = rowTeasers
          .map(teaser => teaser.querySelector<HTMLElement>(fieldClass))
          .filter((el): el is HTMLElement => el !== null);

        const heights = elements.map(el => el.clientHeight);
        const maxHeight = Math.max(...heights);

        elements.forEach(el => {
          el.style.height = `${maxHeight}px`;
        });
      });
    });
  };

  useEffect(() => {
    if (!gridRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      adjustFieldHeights();
    });

    resizeObserver.observe(gridRef.current);

    // Initial adjustment
    adjustFieldHeights();

    return () => {
      resizeObserver.disconnect();
    };
  }, [nodes]);

  products = getTermProducts();

  // Build a list of available filters for the product list.
  if (nodes?.length) {
    for (let product of nodes) {
      if(product?.field_product_brand) {
        let brand: DrupalTaxonomyTerm = product?.field_product_brand;
        if (!brands[brand.id]) {
          brands[brand.id] = brand;
        }
      }
      if (Array.isArray(product?.field_product_test_targets)) {
        for (let target of product.field_product_test_targets) {
          if (!testTargets[target.id]) {
            testTargets[target.id] = target;
          }
        }
      }
      if(product?.field_product_testing_method) {
        let technology = product?.field_product_testing_method;
        if (!technologies[technology.id]) {
          technologies[technology.id] = technology;
        }
      }
      // Certificates are actually filtered by their type.
      if (Array.isArray(product?.field_product_certificates)) {
        if (product.field_product_certificates.length === 0) {
          noCertificateFilter = true;
        }
        else {
          for (let certificate of product.field_product_certificates) {
            if (certificate?.field_certificate_type?.name && !certificates[certificate.field_certificate_type.id]) {
              certificates[certificate.field_certificate_type.id] = certificate?.field_certificate_type;
            }
          }
        }
      }
      else {
        noCertificateFilter = true;
      }
    }
    brands = Object.values(brands);
    brands.sort((a, b) => {
      if (a?.name < b?.name) return -1;
      if (a?.name > b?.name) return 1;
      return 0;
    });
    certificates = Object.values(certificates);
    certificates.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    testTargets = Object.values(testTargets);
    testTargets.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    technologies = Object.values(technologies);
    technologies.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  /**
   * Will match either the current term or its parents.
   * @param term
   *   The term to check.
   * @param term_id
   *   The target term id to check for.
   */
  function termParentMatch(term, term_id) {
    if (term?.id === term_id) {
      return true;
    }
    if (term?.parent?.[0]) {
      return termParentMatch(term.parent[0], term_id);
    }
  }

  /**
   * Get all products for the current term, filtered by test target if present.
   */
  function getTermProducts(): DrupalProductNode[] {
    // If we are filtering the page by a test target, show only products that
    // have that test target or is a child of the test target.
    let products = nodes?.filter(product => {
      if (!subtype) {
        return product
      }
      for (let target of product.field_product_test_targets) {
        if (target.id === subtype.id) {
          return product;
        }
        if (!target?.parent?.length) {
          continue;
        }
        for (let parent of target.parent) {
          if (parent.id === subtype.id) {
            return product;
          }
        }
      }
    });

    // Now filter by any selected filters: use AND method.
    if (certificateFilter || targetFilter || technologyFilter || brandFilter || kitFilter) {
      products = products?.filter(product => {
        let match;
        let filteredProduct: null|DrupalNode = product;
        if (certificateFilter && filteredProduct) {
          match = false;
          // Allow filtering by products with no certificates.
          if (!filteredProduct?.field_product_certificates) {
            filteredProduct = null;
          }
          if (!filteredProduct?.field_product_certificates?.length) {
            match = certificateFilter === "no-certificates";
          }
          else {
            for (let certificate of filteredProduct.field_product_certificates) {
              if (certificate?.field_certificate_type?.id && certificate?.field_certificate_type?.id === certificateFilter) {
               match = true;
               break;
              }
            }
          }
          filteredProduct = match ? filteredProduct : null;
        }
        if (targetFilter && filteredProduct) {
          match = false;
          if (!filteredProduct?.field_product_test_targets) {
            filteredProduct = null;
          }
          for (let target of filteredProduct?.field_product_test_targets ?? []) {
            if (termParentMatch(target, targetFilter)) {
              match = true;
              break;
            }
          }
          filteredProduct = match ? filteredProduct : null;
        }
        if (technologyFilter && filteredProduct) {
          if (technologyFilter !== filteredProduct?.field_product_testing_method?.id) {
            filteredProduct = null;
          }
        }
        if (brandFilter && filteredProduct) {
          if (brandFilter !== filteredProduct?.field_product_brand?.id) {
            filteredProduct = null;
          }
        }
        if (kitFilter === "multi" && !filteredProduct?.field_product_multiplex_kit) {
          filteredProduct = null;
        }
        if (kitFilter === "single" && filteredProduct?.field_product_multiplex_kit) {
          filteredProduct = null;
        }
        return filteredProduct;
      })
    }

    return products ?? [];
  }

  /**
   * Set a filter and apply it to the URL query.
   *
   * @param callback
   * @param value
   */
  async function setFilter(callback: Function, value: string) {
    await callback(value);
    if (typeof decodeURIComponent === "undefined" || typeof window === "undefined") return;
    let filter: string|string[]|undefined = "";
    const query = {};
    const pathname = decodeURIComponent(router.asPath).split("?")?.[0] ?? "/";
    // reset the page.
    setPage(0);

    if (brandFilter || value) {
      filter = callback === setBrandFilter ? value : brandFilter;
      if (filter?.length) query["brand"] = filter;
    }
    if (certificateFilter || value) {
      filter = callback === setCertificateFilter ? value : certificateFilter;
      if (filter?.length) query["certificate"] = filter;
    }
    if (targetFilter || value) {
      filter = callback === setTargetFilter ? value : targetFilter;
      if (filter?.length) query["target"] = filter;
    }
    if (technologyFilter || value) {
      filter = callback === setTechnologyFilter ? value : technologyFilter;
      if (filter?.length) query["technology"] = filter;
    }
    if (kitFilter || value) {
      filter = callback === setKitFilter ? value : kitFilter;
      if (filter?.length) query["kit_type"] = filter;
    }

    await router.push({pathname, query}, undefined, {shallow: true});
  }

  /**
   * Removes all active filters.
   */
  async function resetFilters() {
    const query = {};
    const pathname = decodeURIComponent(router.asPath).split("?")?.[0] ?? "/";
    setPage(0);
    for (let filter of ["brand", "certificate", "target", "technology", "kit_type", "page"]) {
      delete(query[filter]);
    }
    for (const callback of [setBrandFilter, setCertificateFilter, setTargetFilter, setTechnologyFilter, setKitFilter]) {
      callback("");
    }
    await router.push({pathname, query}, undefined, {shallow: true});
  }

  function getFilterValue(type?: string): string | undefined {
    if (type && router?.query?.[type]) {
      return router?.query?.[type] as string ?? undefined;
    }
    return "";
  }

  useEffect(() => {
    const certificate = getFilterValue("certificate");
    const technology = getFilterValue("technology");
    const target = getFilterValue("target");
    const brand = getFilterValue("brand");
    const kitType = getFilterValue("kit_type");

    // Set page from query string if pagination is enabled.
    if (paginate) {
      const pageValue = getFilterValue('page') ?? "";
      if (!isNaN(parseInt(pageValue))) {
        const value = parseInt(pageValue);
        if (value < pageLimit || value === 0) {
          setPage(parseInt(pageValue));
        }
      }
    }

    // Prevent filters that no longer exist from filtering products.
    if (certificate === "no-certificates" || certificates?.filter(item => item?.id === certificate)?.length) {
      setCertificateFilter(certificate);
    }
    if (technologies?.filter(item => item?.id === technology)?.length) {
      setTechnologyFilter(technology);
    }
    if (testTargets?.filter(item => item?.id === target)?.length) {
      setTargetFilter(target);
    }
    if (brands?.filter(item => item?.id === brand)?.length) {
      setBrandFilter(brand);
    }
    if (kitType === "multi" || kitType === "single") {
      setKitFilter(kitType);
    }

  }, [router?.query])

  hasFilters = (certificates?.length || testTargets?.length || technologies?.length || brands?.length) > 1;


  // If there's a nodeorder, sort by that.
  if (nodeorder) {
    products = products.sort((a, b) => {
      if (nodeorder?.[a?.drupal_internal__nid] && nodeorder?.[b?.drupal_internal__nid]) {
        // @ts-ignore
        return nodeorder[a.drupal_internal__nid] - nodeorder[b.drupal_internal__nid];
      }
      return nodeorder?.[a?.id] - nodeorder?.[b?.id];
    })
  }
  // Otherwise sort products by brand and then by name.
  else {
    products = products.sort((a, b) => a?.title?.localeCompare(b.title ?? ""));
    products = products.sort((a, b) => {
      const brandA = a?.field_product_brand?.name?.toLowerCase() ?? "";
      const brandB = b?.field_product_brand?.name?.toLowerCase() ?? "";
      return brandA.localeCompare(brandB);
    });
  }

  filterable = filters && hasFilters && nodes && nodes.length > 4;

  /**
   * Returns true if an option should be disabled.
   *
   * @param id
   */
  function optionIsDisabled(id: string) {
    if (availableFilterIds?.length === 0) return false;
    return !availableFilterIds?.includes(id);
  }

  // Build out a list of available IDs, so we know when to disable items.
  if (nodes?.length) {
    for (let node of nodes) {
      const brandMatch = brandFilter && node?.field_product_brand?.id === brandFilter;
      const technologyMatch = technologyFilter && node?.field_product_testing_method?.id === technologyFilter;
      const certificateMatch = certificateFilter && node?.field_product_certificates?.filter(item => item?.field_certificate_type?.id === certificateFilter)?.length > 0;
      const targetMatch = targetFilter && node?.field_product_test_targets?.filter(item => item?.id === targetFilter)?.length > 0;
      const kitTypeMatch = !kitFilter || kitFilter === "multi" && node?.field_product_multiplex_kit || kitFilter === "single" && !node?.field_product_multiplex_kit;

      if (brandFilter && !brandMatch) continue;
      if (kitFilter && !kitTypeMatch) continue;
      if (technologyFilter && !technologyMatch) continue;
      if (certificateFilter && !certificateMatch) continue;
      if (targetFilter && !targetMatch) continue;

      if (node?.field_product_multiplex_kit) {
        availableFilterIds.push("multi");
      }
      else {
        availableFilterIds.push("single");
      }
      if (node?.field_product_brand?.id && !availableFilterIds.includes(node.field_product_brand.id)) {
        availableFilterIds.push(node.field_product_brand.id);
      }
      if (node?.field_product_certificates) {
        for (const certificate of node.field_product_certificates) {
          if (!certificate?.field_certificate_type?.id) continue;
          if (!availableFilterIds.includes(certificate?.field_certificate_type?.id)) {
            availableFilterIds.push(certificate.field_certificate_type.id);
          }
        }
      }
      if (node?.field_product_test_targets) {
        for (const target of node.field_product_test_targets) {
          if (!target?.id) continue;
          if (!availableFilterIds.includes(target.id)) availableFilterIds.push(target.id);
        }
      }
      if (node?.field_product_testing_method?.id && !availableFilterIds.includes(node.field_product_testing_method.id)) {
        availableFilterIds.push(node.field_product_testing_method.id);
      }
      if (node?.field_product_multiplex_kit) {
        availableFilterIds.push("multi");
      }
      else {
        availableFilterIds.push("single");
      }
    }
  }

  function productFilterResetButton() {
    return (
      <>
        {(technologyFilter || brandFilter || certificateFilter || targetFilter || kitFilter) ? (
          <div onClick={resetFilters} className="reset-filters ml-3 animate-fade-in-slow">
            <div className="bg-accent-red h-[30px] w-[30px] rounded-md cursor-pointer relative flex justify-center">
              <div className="bg-white h-[26px] w-[4px] rounded-md absolute left-[13px] top-[2px] rotate-45"/>
              <div className="bg-white h-[26px] w-[4px] rounded-md absolute left-[13px] top-[2px] -rotate-45"/>
            </div>
          </div>
        ): <></>}
      </>
    )
  }

  /**
   * Returns true if a filter is applied.
   * @return boolean
   */
  function isFiltered(): boolean {
    for (const i of ['brand', 'certificate', 'kit_type', 'target']) {
      if (getFilterValue(i)?.length) {
        return true;
      }
    }
    return false;
  }

  /**
   * Changes the current page.
   * @param page
   */
  const changePage = async (page) => {
    const pathname = decodeURIComponent(router.asPath).split("?")?.[0] ?? "/";
    setPage(page);
    await router.push({pathname, query: {
      page,
    }}, undefined, {shallow: true});
  }

  // Final product mapping, to take into account pagination.
  productCount = products?.length ?? 0;
  if (paginate && !isFiltered()) {
    products = products.slice(page * pageLimit, (page * pageLimit) + pageLimit);
  }

  return (
    <>
      {filterable ? (
        <div className={classNames("flex md:justify-end mb-6 pt-2", filterClassName ?? "")}>
          <div className="w-full lg:w-auto">
            <div className="flex justify-end items-center mb-4 lg:mb-0 md:hidden">
              <span className="pr-2 block lg:inline">{`${t?.all?.filter_products}`}:</span>
              {productFilterResetButton()}
            </div>
            <div className="flex flex-col md:flex-row gap-1">
              <span className="pr-2 block max-md:hidden self-center lg:min-w-[140px]">{`${t?.all?.filter_products}`}:</span>
              {brands?.length > 1 ? (
                <Select name="brand-filter" value={getFilterValue("brand")} onValueChange={val => setFilter(setBrandFilter, val)}>
                  <SelectTrigger className={classNames("border-hygienaGray border rounded-sm text-left font-normal bg-white w-auto lg:min-w-[140px] flex-shrink-0")}>
                    <SelectValue placeholder={`${t?.all?.all_brands}`} />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(brands).map(key => {
                      const target = brands[key];
                      return <SelectItem value={target.id} key={target.id} disabled={optionIsDisabled(target.id)}>{target.name}</SelectItem>
                    })}
                  </SelectContent>
                </Select>
              ): <></>}
              {certificates?.length > 1 ? (
                <Select name="certificate-filter" value={getFilterValue("certificate")} onValueChange={val => setFilter(setCertificateFilter, val)}>
                  <SelectTrigger className={classNames("border-hygienaGray border rounded-sm text-left font-normal bg-white w-auto lg:min-w-[140px] flex-shrink-0")}>
                    <SelectValue placeholder={`${t?.all?.all_certificates}`} />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(certificates).map(key => {
                      const target = certificates[key];
                      return <SelectItem value={target.id} key={target.id} disabled={optionIsDisabled(target.id)}>{target.name}</SelectItem>
                    })}
                  </SelectContent>
                </Select>
              ) : <></>}
              <Select name="kit-type-filter" value={getFilterValue("kit_type")} onValueChange={val => setFilter(setKitFilter, val)}>
                <SelectTrigger className={classNames("border-hygienaGray border rounded-sm text-left font-normal bg-white w-auto lg:min-w-[140px] flex-shrink-0")}>
                  <SelectValue placeholder={`${t?.all?.all_reaction_types}`} />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={"multi"} key={'kit-type'} disabled={optionIsDisabled("multi")}>{t?.all?.multiplex_assay}</SelectItem>
                  <SelectItem value={"single"} key={'kit-type'} disabled={optionIsDisabled("single")}>{t?.all?.singleplex_assay}</SelectItem>
                </SelectContent>
              </Select>
              {testTargets?.length > 1 ? (
                <Select name="targets-filter" value={getFilterValue("target")} onValueChange={val => setFilter(setTargetFilter, val)}>
                  <SelectTrigger className={classNames("border-hygienaGray border rounded-sm text-left font-normal bg-white w-auto lg:min-w-[140px] flex-shrink-0")}>
                    <SelectValue placeholder={`${t?.all?.all_targets}`} />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(testTargets).map(key => {
                      const target = testTargets[key];
                      return <SelectItem value={target.id} key={target.id} disabled={optionIsDisabled(target.id)}>{target.name}</SelectItem>
                    })}
                  </SelectContent>
                </Select>
              ): <></>}
              {technologies?.length > 1 ? (
                <Select name="technologies-filter" value={getFilterValue("technology")} onValueChange={val => setFilter(setTechnologyFilter, val)}>
                  <SelectTrigger className={classNames("border-hygienaGray border rounded-sm text-left font-normal w-auto flex-shrink-0 bg-white")}>
                    <SelectValue placeholder={`${t?.all?.all_technologies}`} />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(technologies).map(key => {
                      const target = technologies[key];
                      return <SelectItem value={target.id} key={target.id} disabled={optionIsDisabled(target.id)}>{target.name}</SelectItem>
                    })}
                  </SelectContent>
                </Select>
              ): <></>}
              <div className="max-md:hidden flex items-center justify-center">{productFilterResetButton()}</div>
            </div>
          </div>
        </div>
      ): <></>}
      <div
        ref={gridRef}
        className={classNames(
        "grid gap-2 py-6",
        className ?? "",
        {"product-grid-container": products?.length > 1},
        {"grid-cols-1 lg:grid-cols-2": fixed === 2},
        {"grid-cols-1 lg:grid-cols-3": fixed === 3},
        {"grid-cols-1 lg:grid-cols-4": fixed === 4},
        {"grid-cols-1 lg:grid-cols-1": !fixed && (products.length === 1)},
        {"grid-cols-1 lg:grid-cols-2": !fixed && (products.length % 2 === 0 && products.length < 5)},
        {"grid-cols-1 lg:grid-cols-3": !fixed && (products.length % 3 === 0 || products.length > 4 && products.length !== 1)},
        !filterable ? (noFiltersClassName ?? "") : "",
      )}>
        {products.length > 1 ? (
          products.map(node => <NodeProductTeaser key={node.id} node={node} />)
        ): (
          products.map(node => (
            <NodeSubProductContainer key={node.id}><NodeSubProduct node={node} /></NodeSubProductContainer>)
          )
        )}
      </div>
        {(paginate && !isFiltered()) && (
          <>
            <Pagination count={productCount} page={page} limit={pageLimit} changePage={changePage} radius={3} scrollRef={gridRef} links={paginate > 0} />
          </>
        )}
    </>
  )
}

