import classNames from "classnames";
import {MutableRefObject, RefObject, useContext} from "react";
import {Dictionary} from "../../types/hygiena-types";
import {DictionaryContext} from "../../context/dictionary-context";

type PaginationProps = {
  count: number, 
  page: number, 
  limit: number, 
  changePage: (page: number) => void,
  radius: number, 
  scrollRef?: string|RefObject<any>|MutableRefObject<any>|null,
  className?: string,
  links?: boolean,
}

/**
 * Builds the document search pagination.
 *
 * @todo this is being duplicated in document search.
 *
 * @constructor
 */
export function Pagination({
  count,
  page,
  limit,
  changePage,
  radius,
  scrollRef = null,
  className = "",
  links = false,
}: PaginationProps): JSX.Element {
  const t = useContext<Dictionary>(DictionaryContext);
  const pages = Math.ceil(count / limit);
  const buttonClasses = "bg-hygienaLight border-gray-300 border px-4 py-2 rounded-md font-bold text-sm transition-all duration-500 hover:bg-primary hover:border-primary hover:text-white";
  const firstPage = page <= 0;
  const lastPage = page + 1 >= pages;
  let pageRange: number[] = [];
  let radiusOffsetStart = 0;
  let radiusOffsetEnd = 0;
  let diff = 0;
  pageRange = !isNaN(pages) ? Array.from(Array(pages).keys()) : [0];

  /**
   * Scrolls back to the top of the document search.
   *
   * @todo this is used in a few places, refactor it into a reusable function.
   */
  function scrollToRef() {
    if (typeof scrollRef === "string") {
      const element = document.getElementById(scrollRef);
      if (element) element.scrollIntoView({behavior: "smooth", block: "start"});
    }
    else if (scrollRef?.current) {
      const current = scrollRef.current;
      current.scrollIntoView({behavior: 'smooth'})
    }
  }

  // The radius is to always keep the desired number of items showing i.e. 6 will
  // show something like this ... 8 9 10 11 12 13 14 15 16 ...
  if (pages > radius) {
    // Calculate the start offset.
    radiusOffsetStart = page - (radius / 2);
    radiusOffsetStart = radiusOffsetStart > 0 ? radiusOffsetStart : 0;

    // Calculate the end offset.
    radiusOffsetEnd = (radius / 2) + page + 1;
    radiusOffsetEnd = radiusOffsetEnd > pages ? pages : radiusOffsetEnd
    radiusOffsetEnd = radiusOffsetEnd < radius ? radius: radiusOffsetEnd;

    // Check for a radius difference to see if we need to add some back padding.
    diff = radius - (radiusOffsetEnd - radiusOffsetStart);
    if (diff > 1) {
      radiusOffsetStart = radiusOffsetStart - diff;
    }

    // Calculate the page range.
    pageRange = pageRange.slice(Math.floor(radiusOffsetStart), Math.ceil(radiusOffsetEnd));
  }

  function onClick(val) {
    changePage(val);
    scrollToRef();
  }

  return (
    <>
      {pages > 1 ? (
        <div className={classNames("flex flex-grow flex-wrap gap-4 justify-center py-12", className ?? "")}>
          <button type="button" onClick={() => onClick(0)} className={classNames(buttonClasses, "max-md:hidden", {"opacity-30 pointer-events-none": firstPage})}>
            {links ? (
              <a href={`?page=0`} onClick={(e) => e.preventDefault()}>
                {`${t?.documents?.first}`}
              </a>
            ): (
              <>
                {`${t?.documents?.first}`}
              </>
            )}
          </button>
          <button type="button" onClick={() => onClick(page - 1)} className={classNames(buttonClasses, {"opacity-30 pointer-events-none": firstPage})}>
            {links ? (
              <a href={`?page=${page - 1}`} onClick={(e) => e.preventDefault()}>
                {`${t?.documents?.prev}`}
              </a>
            ): (
              <>
                {`${t?.documents?.prev}`}
              </>
            )}
          </button>
          <div className="flex gap-4 flex-grow justify-center items-center text-center">
            {pageRange && pageRange[0] > 0 && <span>...</span>}
            {pageRange.map(i => (
              <button key={i} onClick={() => onClick(i)} className={classNames({"text-primary font-bold": i === page})}>
                {links ? (
                  <a href={`?page=${i}`} onClick={(e) => e.preventDefault()}>
                    {i + 1}
                  </a>
                ): (
                  <>
                    {i + 1}
                  </>
                )}
              </button>
            ))}
            {pageRange && pageRange.slice(-1)[0] < pages - 1 && <span>...</span>}
          </div>
          <button type="button" onClick={() => onClick(page + 1)} className={classNames(buttonClasses, {"opacity-30 pointer-events-none": lastPage})}>
            {links ? (
              <a href={`?page=${page + 1}`} onClick={(e) => e.preventDefault()}>
                {`${t?.documents?.next}`}
              </a>
            ): (
              <>
                {`${t?.documents?.next}`}
              </>
            )}
          </button>
          <button type="button" onClick={() => onClick(pages - 1)} className={classNames(buttonClasses, "max-md:hidden", {"opacity-30 pointer-events-none": lastPage})}>
            {links ? (
              <a href={`?page=${pages - 1}`} onClick={(e) => e.preventDefault()}>
                {`${t?.documents?.last}`}
              </a>
            ): (
              <>
                {`${t?.documents?.last}`}
              </>
            )}
          </button>
        </div>
      ) : <></>}
    </>
  )
}
