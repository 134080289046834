import classNames from "classnames";
import dynamic from "next/dynamic";
const FormattedText = dynamic(() => import("../../formatted-text").then((mod) => mod.FormattedText));

export function ParagraphWysiwyg({paragraph, ...props}) {
  const fontSize = paragraph?.field_wysiwyg_font_size ?? "text-base";
  const color = paragraph?.field_wysiwyg_color ?? "dark";
  return (
    <div className="prose text-base font-light text-black lg:text-xl">
      <FormattedText processed={paragraph?.field_body?.processed}/>
    </div>
  )
}